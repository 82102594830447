<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <router-link to="/">Inicio</router-link> /
      </template>
      <template slot="area-titulo">
        <h4>{{ item.titulo }}</h4>
      </template>
    </titulo-seccion>

    <div id="content-page" class="container mt-4">
      <div class="row">
        <div class="col-md-12">
          <div v-if="item.imagen" class="img-portada lg-round pt-0 mt-4">
            <img
              v-lazy="item.imagen"
              class="img-fluid img-responsive"
              :alt="item.titulo"
              :title="item.titulo"
            />
          </div>
          <div :class="item.imagen ? 'mt-4' : 'mt-0'">
            <!-- <h3 class="titulo" data-aos="fade-up">{{ item.titulo }}</h3> -->
            <div
              class="mt-2 format-content-pagina"
              :style="{
                '--background-color-tertiary':
                  config.disenio.datos.color_terciario,
                '--color-secondary': config.disenio.datos.color_secundario,
                '--color-primary': config.disenio.datos.color_primario,
              }"
              data-aos="fade-up"
              v-html="item.contenido"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import TituloSeccion from "./secciones/TituloSeccion.vue";

export default {
  components: {
    TituloSeccion,
  },
  data() {
    return {
      item: [],
      preview: "",
    };
  },
  metaInfo() {
    return {
      title: "Ministerio Público",
      htmlAttrs: {
        lang: "es-ES",
      },
      titleTemplate: "%s | " + this.item.titulo,
      meta: [
        { vmid: "description", name: "description", content: this.item.titulo },
      ],
    };
  },
  mounted() {
    if (this.$route.params.slug) {
      if (this.$route.query.preview) {
        this.preview = this.$route.query.preview;
      }
      this.getPagina(this.$route.params.slug);
    }
  },
  computed: {
    config() {
      return this.$store.state.config;
    },
  },
  watch: {
    $route: function (val) {
      if (val.params.slug) {
        this.getPagina(this.$route.params.slug);
      }
    },
  },
  methods: {
    getPagina(slug) {
      var url = "/web/pagina/" + slug + "?preview=" + this.preview;
      axiosIns
        .get(url)
        .then((res) => {
          if (res.data) {
            this.item = res.data;
          } else {
            this.$router.push({ name: "error-404" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>

.format-content-pagina {
  background-color: var(--background-color-tertiary) !important;
  padding: 1rem;
  border-radius: 10px;
}
.format-content-pagina * {
  text-align: justify !important;
  background-color: var(--background-color-tertiary) !important;
  width: 100% !important;
}
.format-content-pagina img {
  display: block;
  margin: 0 auto;
  padding: 20px;
}

.format-content-pagina .table {
  width: 100% !important;
}

.format-content-pagina .table table {
  width: 100% !important;
}
.format-content-pagina .table tbody tr td {
  padding: 10px !important;
  color: var(--color-secondary);
  border-radius: 10px !important;
}

.format-content-pagina .table tbody tr td a {
  padding: 10px !important;
  color: var(--color-primary);
}

.format-content-pagina .table tbody tr td:has(a):hover,
.format-content-pagina .table tbody tr td:has(a):hover a {
  background-color: var(--color-secondary) !important;
  color: white !important;
}

.format-content-pagina h3 *,
.format-content-pagina h3 {
  font-size: 1rem !important;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--color-secondary);
}

.format-content-pagina h2,
.format-content-pagina h2 * {
  font-size: 1.2rem !important;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--color-secondary);
}

.format-content-pagina h1 {
  font-size: 1.5rem !important;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--color-secondary);
}

.format-content-pagina h2,
.format-content-pagina h1 {
  text-align: center !important;
}
</style>
